import { AccessLevel } from '@capturi/api-shared'
import { PreviewAccessRequestModel } from '@capturi/core'
import request, { RequestOptions, ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreateDashboardFolderRequestModel,
  DashboardFolder,
  DashboardFoldersListResponseModel,
  DashboardsListResponseModel,
} from '../types'
import { cacheKey as dashboardCacheKey } from './useDashboards'

const DEFAULT_API_VERSION = '3.3'
const controller = 'dashboards/folders'
const url = `${controller}?api-version=${DEFAULT_API_VERSION}`
const cacheKey = 'dashboard-folders'

export const previewAccessRequest = (
  model: PreviewAccessRequestModel,
): RequestOptions => ({
  url: `${controller}/preview-access`,
  query: { 'api-version': DEFAULT_API_VERSION },
  method: 'post',
  json: model,
})

export const useCreateDashboardFolder = (): UseMutationResult<
  DashboardFolder,
  ResponseError,
  CreateDashboardFolderRequestModel
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: CreateDashboardFolderRequestModel) =>
      request.post<DashboardFolder>(url, { json: model }),
    onSuccess: (newDashboard) => {
      queryClient.setQueryData<DashboardFoldersListResponseModel>(
        [cacheKey],
        (oldData) => {
          if (!oldData) {
            return { folders: [newDashboard] }
          }
          return { folders: [newDashboard, ...oldData.folders] }
        },
      )
    },
  })
}

type UpdateDashboardFolderRequestModel = {
  uid: string
  folder: CreateDashboardFolderRequestModel
}
export const useUpdateDashboardFolder = (): UseMutationResult<
  DashboardFolder,
  ResponseError,
  UpdateDashboardFolderRequestModel
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ uid, folder }: UpdateDashboardFolderRequestModel) =>
      request.patch<DashboardFolder>(
        `${controller}/${uid}?api-version=${DEFAULT_API_VERSION}`,
        { json: folder },
      ),
    onSuccess: (newDashboard) => {
      queryClient.setQueryData<DashboardFoldersListResponseModel>(
        [cacheKey],
        (oldData) => {
          if (!oldData) {
            return { folders: [newDashboard] }
          }
          return {
            folders: oldData.folders.map((f) =>
              f.uid === newDashboard.uid ? newDashboard : f,
            ),
          }
        },
      )
    },
  })
}

export const useDeleteDashboardFolder = (): UseMutationResult<
  DashboardFolder,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (folderUid: string) =>
      request.delete<DashboardFolder>(
        `${controller}/${folderUid}?api-version=${DEFAULT_API_VERSION}`,
      ),
    onSuccess: (_, folderUid) => {
      queryClient.setQueryData<DashboardFolderResponse | undefined>(
        [cacheKey],
        (oldData) => {
          if (!oldData) return oldData
          return {
            folders: oldData.folders.filter((f) => f.uid !== folderUid),
          }
        },
      )

      queryClient.setQueryData<DashboardsListResponseModel | undefined>(
        [dashboardCacheKey],
        (oldData) => {
          if (!oldData) return oldData
          const dashboardsWithoutFolder = oldData.dashboards.map((d) =>
            d.folderUid === folderUid ? { ...d, folderUid: null } : d,
          )
          return { dashboards: dashboardsWithoutFolder }
        },
      )
    },
  })
}

export type DashboardFolderResponse = {
  folders: DashboardFolder[]
}

const selectorWithAccessLevel =
  (
    accessLevel?: AccessLevel,
  ): ((data: DashboardFolderResponse) => DashboardFolder[]) =>
  (data) => {
    return data.folders.filter((d) => {
      if (accessLevel === undefined) {
        return d.accessLevel !== 'None'
      }
      return d.accessLevel === accessLevel
    })
  }

export const useDashboardFolders = (
  accessLevel?: AccessLevel,
): UseQueryResult<DashboardFolder[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () => request.get<DashboardFoldersListResponseModel>(url),
    select: selectorWithAccessLevel(accessLevel),
  })

const selectorOneAccessible =
  (
    folderUid: string | undefined,
  ): ((data: DashboardFolderResponse) => DashboardFolder | undefined) =>
  (data) => {
    return folderUid !== undefined
      ? data.folders.find(
          (f) => f.uid === folderUid && f.accessLevel !== 'None',
        )
      : undefined
  }

export const useDashboardFolder = (
  folderUid: string | undefined,
): UseQueryResult<DashboardFolder | undefined, ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () => request.get<DashboardFolderResponse>(url),
    select: selectorOneAccessible(folderUid),
    enabled: !!folderUid,
  })
