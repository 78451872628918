import { useCurrentUser } from '@capturi/core'
import { Button } from '@capturi/ui-components'
import {
  MenuProps,
  OnChangeValue,
  PopoutSelect,
  SelectOption,
} from '@capturi/ui-select'
import { useModal } from '@capturi/use-modal'
import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { MdClear, MdInfoOutline } from 'react-icons/md'

import { DashboardAdminEvent, logEvent } from '../../../events'
import { useDashboardFolders } from '../../../hooks/useDashboardFolders'
import { CreateDashboardModel } from '../../../types'
import { CreateUpdateDashboardFolderDialog } from '../../CreateUpdateDashboardFolderDialog'
import { CreateFolderMenu } from './CreateFolderMenu'

const NoOptionsMessage: React.FC = () => {
  return (
    <Flex direction="column" p={4}>
      <Text>
        <Trans>No folders have been created yet.</Trans>
      </Text>
    </Flex>
  )
}

export const DashboardFolderField: React.FC<{
  formState: CreateDashboardModel
  setFormState: (state: Partial<CreateDashboardModel>) => void
}> = ({ formState, setFormState }) => {
  const currentUser = useCurrentUser()
  const { data: folders } = useDashboardFolders('Edit')

  const { isOpen, onToggle, onClose } = useDisclosure()

  const selectedFolder = React.useMemo(() => {
    return folders?.find((f) => f.uid === formState.folderUid)
  }, [folders, formState.folderUid])

  const options = React.useMemo(() => {
    return (folders ?? []).map((f) => ({
      value: f.uid,
      label: f.title ?? '',
    }))
  }, [folders])

  const changeFolder = React.useCallback(
    (folderUid: string | null) => {
      setFormState({ folderUid })

      if (folderUid === null) {
        if (formState.permissionPreset === 'Inherit') {
          setFormState({ permissionPresetUserUid: currentUser.id })
          setFormState({ permissionPreset: 'Private' })
          setFormState({ acl: null })
        }
      } else {
        setFormState({ permissionPresetUserUid: null })
        setFormState({ permissionPreset: 'Inherit' })
        setFormState({ acl: null })
      }
    },
    [currentUser.id, formState.permissionPreset, setFormState],
  )

  const handleClear = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      changeFolder(null)
      logEvent(DashboardAdminEvent.DashboardEditorFolderCleared)
    },
    [changeFolder],
  )

  const [openCreateFolderDialog] = useModal(CreateUpdateDashboardFolderDialog)

  const handleCreateFolder = React.useCallback(async () => {
    openCreateFolderDialog({
      onFolderCreated: (folder) => {
        if (folder?.accessLevel === 'Edit') {
          changeFolder(folder.uid)
          logEvent(DashboardAdminEvent.DashboardFolderCreated, {
            permissionPreset: folder.permissionPreset,
          })
        }
        onClose()
      },
    })
  }, [changeFolder, onClose, openCreateFolderDialog])

  const handleFolderSelection = React.useCallback(
    (option: OnChangeValue<SelectOption, false>) => {
      changeFolder(option?.value ?? null)
      onClose()
      logEvent(DashboardAdminEvent.DashboardEditorFolderSelected)
    },
    [changeFolder, onClose],
  )

  const Menu = React.useCallback(
    (props: MenuProps<SelectOption, boolean>) => (
      <CreateFolderMenu {...props} onCreateFolder={handleCreateFolder} />
    ),
    [handleCreateFolder],
  )

  return (
    <FormControl>
      <FormLabel htmlFor="dashboard-folder">
        <chakra.span>
          <Trans>Folder</Trans>
          <Tooltip
            hasArrow
            label={t`Add dashboard to a folder, thus making it easier to find a specific dashboard when having many.`}
          >
            <chakra.span ml={1}>
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </chakra.span>
      </FormLabel>

      <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            iconSpacing={0}
            justifyContent="space-between"
            onClick={onToggle}
            px="8px"
            rightIcon={<Icon as={FiChevronDown} className="open-indicator" />}
            variant="form-select-field"
            minW="100px"
            w="100%"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              overflow="hidden"
            >
              {selectedFolder?.title ? (
                <>
                  {selectedFolder?.title}
                  <Icon
                    aria-label={t`Clear`}
                    as={MdClear}
                    className="clear"
                    onClick={handleClear}
                    transition={'color 0.2s'}
                  />
                </>
              ) : (
                <Text className="placeholder">
                  <Trans>Add to folder</Trans>
                </Text>
              )}
            </Flex>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <React.Suspense>
            <PopoutSelect
              id="dashboard-folder"
              components={{
                NoOptionsMessage,
                Menu: Menu,
              }}
              isMulti={false}
              isSearchable={false}
              hideSelectedOptions={false}
              maxMenuHeight={328}
              onChange={handleFolderSelection}
              options={options}
              placeholder={t`Add to folder`}
              value={{
                value: formState.folderUid ?? '',
                label: selectedFolder?.title ?? formState.folderUid ?? '',
              }}
            />
          </React.Suspense>
        </PopoverContent>
      </Popover>
    </FormControl>
  )
}
