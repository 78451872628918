import { useConfirm } from '@capturi/use-confirm'
import { Text, useToast } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { useDeleteDashboardFolder } from './useDashboardFolders'

type UseDeleteFolderConfirmationDialogProps = {
  folderUid: string | undefined
  onFolderDeleted?: () => void
}

const useDeleteFolderConfirmationDialog = ({
  folderUid,
  onFolderDeleted,
}: UseDeleteFolderConfirmationDialogProps): (() => void) => {
  const { mutate: deleteFolder } = useDeleteDashboardFolder()
  const toast = useToast()
  const confirm = useConfirm()

  const handleDelete = React.useCallback(() => {
    if (!folderUid) return
    deleteFolder(folderUid, {
      onSuccess: () => {
        toast({
          title: t`Folder deleted`,
          status: 'success',
        })
        onFolderDeleted?.()
      },
      onError: (error) => {
        toast({
          title: t`An error occurred`,
          description: error.message,
          status: 'error',
        })
      },
    })
  }, [deleteFolder, folderUid, onFolderDeleted, toast])

  return React.useCallback(async () => {
    try {
      await confirm({
        title: t`Delete dashboard folder`,
        description: (
          <Text mb="4">
            <Trans>
              The dashboards from the folder will be moved out, ensuring that
              the dashboards inside the folder remain unaffected and won`t be
              deleted.
            </Trans>
          </Text>
        ),
        cancelText: t`Cancel`,
        confirmText: t`Delete`,
      })
      handleDelete()
    } catch {
      // cancelled
    }
  }, [confirm, handleDelete])
}

export default useDeleteFolderConfirmationDialog
