import analytics from '@capturi/analytics'
import {
  PreviewAccessRequestModel,
  PreviewAccessResponse,
  Role,
  useCurrentUser,
} from '@capturi/core'
import { PeriodDefinition, getAcl, getPermissionPreset } from '@capturi/filters'
import {
  PersonsWithAccess,
  ShareAndEditOptions,
  SharingModalFormContent,
  usePreviewAccess,
} from '@capturi/sharing'
import {
  Description,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  RadioButton,
  RadioButtonGroup,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { useUsers } from '@capturi/stores'
import api from '../../api'
import { DashboardAdminEvent, logEvent } from '../../events'
import { useDashboardFolder } from '../../hooks/useDashboardFolders'
import {
  CreateDashboardModel,
  Dashboard,
  SharedContextType,
  UpdateDashboardModel,
} from '../../types'
import {
  UPGRADED_DASHBOARD_COLUMNS,
  UPGRADED_DASHBOARD_ROWS,
} from '../../utils/constants'
import AccessKeyItem from './AccessKeyItem'
import { DashboardFolderField } from './DashboardFolderField'

export type CreateUpdateDashboardDialogOptions = {
  dashboard?: Partial<Dashboard>
  periodDef: PeriodDefinition
  addAccessKey?: () => void
  deleteAccessKey?: () => void
  previewAccess?: (
    model: PreviewAccessRequestModel,
  ) => Promise<PreviewAccessResponse>
}

export type CreateUpdateDashboardDialogProps =
  CreateUpdateDashboardDialogOptions &
    BaseModalComponentProps<CreateDashboardModel | UpdateDashboardModel>

const TitleInput: React.FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange, inputRef }) => {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor="dashboard-title">
        <Trans>Name</Trans>
      </FormLabel>
      <Input
        id="dashboard-title"
        ref={inputRef}
        placeholder={t`Dashboard name`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}

const DescTextarea: React.FC<{
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange }) => {
  return (
    <FormControl>
      <FormLabel htmlFor="dashboard-description">
        <Trans>Description</Trans>
      </FormLabel>
      <Textarea
        id="dashboard-description"
        placeholder={t`Add a description that explains what the dashboard should be used for ...`}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}

function getSharedContextValueDescription(type: SharedContextType): string {
  switch (type) {
    case 'Organization':
      return t`Team leaders and users will see this dashboard based on conversations across the organization.`
    case 'Team':
      return t`Team leaders and users will see this dashboard based on their team's conversations.`
    case 'User':
      return t`Team leaders and users will see this dashboard based on their own conversations.`
  }
}

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the dashboard when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the dashboard when you apply because you set your own permission to “viewer” `
  }
}

function initialSharedContextType(
  dashboardSharedContextType: SharedContextType | null | undefined,
  userRole: Role,
): SharedContextType {
  if (dashboardSharedContextType != null) return dashboardSharedContextType

  switch (userRole) {
    case Role.administrator:
      return 'Organization'
    case Role.teamlead:
      return 'Team'
    case Role.user:
      return 'User'
  }
}

export const CreateUpdateDashboardDialog: React.FC<
  CreateUpdateDashboardDialogProps
> = ({
  isOpen = false,
  onClose,
  onSubmit,
  dashboard: dashboardProp,
  periodDef,
  addAccessKey,
  deleteAccessKey,
}) => {
  const toast = useToast()
  // Refs
  const formRef = React.useRef<HTMLFormElement>(null)
  const titleRef = React.useRef<HTMLInputElement>(null)
  const currentUser = useCurrentUser()
  const { getUserByUid } = useUsers()
  const { name: createdByUsername } = getUserByUid(
    dashboardProp?.createdByUserUid,
  )
  const { name: updatedByUsername } = getUserByUid(
    dashboardProp?.updatedByUserUid,
  )
  // State
  const initialPermissionPreset = dashboardProp?.permissionPreset ?? 'Private'
  const initialPermissionPresetUserUid =
    dashboardProp?.permissionPresetUserUid ??
    initialPermissionPreset === 'Private'
      ? currentUser.id
      : null
  const [state, _setState] = React.useState<CreateDashboardModel>(() => ({
    title: dashboardProp?.title ?? '',
    description: dashboardProp?.description ?? '',
    folderUid: dashboardProp?.folderUid ?? null,
    columns: dashboardProp?.columns ?? UPGRADED_DASHBOARD_COLUMNS,
    rows: dashboardProp?.rows ?? UPGRADED_DASHBOARD_ROWS,
    sharedContextType: initialSharedContextType(
      dashboardProp?.sharedContextType,
      currentUser.role,
    ),
    permissionPreset: initialPermissionPreset,
    permissionPresetUserUid: initialPermissionPresetUserUid,
    acl: dashboardProp?.acl ?? [],
    hasWarnings: false,
  }))

  const setState = React.useCallback(
    (partialState: Partial<CreateDashboardModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const { data: folder } = useDashboardFolder(state.folderUid ?? undefined)

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: dashboardProp?.uid,
    permissionPreset: state.permissionPreset,
    permissionPresetUserUid: state.permissionPresetUserUid ?? null,
    acl: state.acl ?? null,
    folderUid: state.folderUid ?? null,
  }
  const { isPreviewAccessLoading, previewAccessResponse, validationError } =
    usePreviewAccess({
      previewAccessRequestModel,
      createPreviewAccessRequest: api.dashboards.getPreviewAccess,
    })

  if (
    previewAccessResponse &&
    state.hasWarnings !==
      ['NoAccess', 'Viewable'].includes(previewAccessResponse)
  ) {
    setState({
      hasWarnings: ['NoAccess', 'Viewable'].includes(previewAccessResponse),
    })
  }

  const localizedEntityName = t`dashboards`
  const showsACL = state.permissionPreset === 'Custom'

  const handleSubmitChanges = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    if (dashboardProp?.permissionPreset !== state.permissionPreset)
      analytics.event('dashboard_edit_view_updated', {
        from: dashboardProp?.permissionPreset,
        to: state.permissionPreset,
      })
    onSubmit?.(state)
  }

  const modalHeader = dashboardProp?.uid
    ? t`Dashboard settings`
    : t`New Dashboard`
  const submitButtonText = dashboardProp?.uid ? t`Save` : t`Create`

  const onToggleShareLink = async (isChecked: boolean): Promise<void> => {
    try {
      logEvent(
        isChecked
          ? DashboardAdminEvent.EnableSharedLink
          : DashboardAdminEvent.DisableSharedLink,
      )
      if (isChecked) {
        await addAccessKey?.()
      } else {
        await deleteAccessKey?.()
      }

      toast({
        status: 'success',
        title: isChecked
          ? t`Dashboard is now shared with a link`
          : t`Dashboard is no longer shared with a link`,
      })
    } catch {
      toast({
        status: 'error',
        title: t`Share status was not updated`,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={titleRef}
      size={'4xl'}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <form ref={formRef} onSubmit={handleSubmitChanges}>
            <SharingModalFormContent
              createdBy={createdByUsername}
              updatedBy={updatedByUsername}
              created={dashboardProp?.created ?? null}
              updated={dashboardProp?.updated ?? null}
              modalHeader={modalHeader}
              submitButtonText={submitButtonText}
              isPreviewAccessLoading={isPreviewAccessLoading}
              previewAccessWarningText={getPreviewAccessWarningText(
                previewAccessResponse,
              )}
              validationError={validationError}
              onClose={onClose}
            >
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: '1fr 1px 1fr',
                }}
                gap={4}
              >
                <Stack
                  borderRight={{
                    base: 'none',
                    md: '1px solid var(--chakra-colors-gray-200)',
                  }}
                  pr={{
                    base: 0,
                    md: 8,
                  }}
                  pb={{ base: 2, md: 0 }}
                  spacing={4}
                >
                  <TitleInput
                    inputRef={titleRef}
                    value={state.title}
                    onChange={(value) => setState({ title: value })}
                  />
                  <DescTextarea
                    value={state.description}
                    onChange={(value) => setState({ description: value })}
                  />
                  <DashboardFolderField
                    formState={state}
                    setFormState={setState}
                  />
                  <FormControl>
                    <FormLabel mb={2}>
                      <Trans>
                        At what level should team leaders and users see data?
                      </Trans>
                    </FormLabel>
                    <RadioButtonGroup
                      name="shared-context-type"
                      value={state.sharedContextType ?? 'Organization'}
                      onChange={(value: SharedContextType) => {
                        setState({
                          sharedContextType: value,
                        })
                      }}
                    >
                      {currentUser.role === Role.administrator && (
                        <RadioButton value="Organization">
                          <Trans>Organization</Trans>
                        </RadioButton>
                      )}
                      {(currentUser.role === Role.administrator ||
                        currentUser.role === Role.teamlead) && (
                        <RadioButton value="Team">
                          <Trans>Team</Trans>
                        </RadioButton>
                      )}
                      <RadioButton value="User">
                        <Trans>User</Trans>
                      </RadioButton>
                    </RadioButtonGroup>
                    {state.sharedContextType && (
                      <Text fontSize="sm" mt={1} color="gray.600">
                        {getSharedContextValueDescription(
                          state.sharedContextType,
                        )}
                      </Text>
                    )}
                    {state.sharedContextType === 'Organization' && (
                      <Text as="span" color="warning" fontSize="sm">
                        <Trans>
                          NB! Team leaders and users with permission to edit
                          this dashboard. Regardless of other settings. This is
                          to prevent accidental access to data. Instead, set
                          context to &apos;Team&apos; or &apos;User&apos; above.
                        </Trans>
                      </Text>
                    )}
                    {state.sharedContextType === 'Team' && (
                      <Text as="span" color="warning" fontSize="sm">
                        <Trans>
                          NB! Users with permission to edit this dashboard will
                          not be able to make changes. Regardless of other
                          settings. This is to prevent accidental access to
                          data. Instead, set context to &apos;User&apos; above.
                        </Trans>
                      </Text>
                    )}
                  </FormControl>
                </Stack>
                <Divider />
                <Flex
                  gap={4}
                  justifyContent="space-between"
                  flexDirection="column"
                  maxH={{
                    base: '24vh',
                    md: dashboardProp?.uid ? '68vh' : '77vh',
                  }}
                  overflowY="auto"
                >
                  <ShareAndEditOptions
                    title={t`View & edit`}
                    permissionPreset={state.permissionPreset}
                    folderPermissionPreset={folder?.permissionPreset}
                    onChange={(permissionPreset) =>
                      setState({ permissionPreset })
                    }
                    localizedEntityName={localizedEntityName}
                  />
                  {showsACL ? (
                    <PersonsWithAccess
                      acl={state.acl || []}
                      onChange={(acl) => setState({ acl: acl })}
                    />
                  ) : (
                    <Box />
                  )}
                </Flex>
              </Grid>
            </SharingModalFormContent>
          </form>

          {dashboardProp?.uid && (
            <Flex
              alignItems="center"
              justify="space-between"
              bg="white"
              borderRadius="md"
              pl={6}
              pr={4}
              py={6}
              // following props place the view underneath the Modal, as a footer with 8px top margin
              position="absolute"
              width="100%"
              top="100%"
              transform="translateY(8px)"
            >
              <HStack>
                <Switch
                  id="share-link-toggle"
                  isChecked={dashboardProp?.accessKey != null}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onToggleShareLink(e.target.checked)
                  }
                />

                <VStack align="start" spacing="0">
                  <FormLabel
                    htmlFor="share-link-toggle"
                    mb="0"
                    fontWeight={
                      dashboardProp?.accessKey != null ? 'medium' : 'normal'
                    }
                  >
                    <Trans>Share dashboard with a link</Trans>
                  </FormLabel>

                  <Description color="gray.600" fontSize="sm">
                    {dashboardProp?.accessKey == null ? (
                      <Trans>
                        The dashboard can&apos;t be viewed via an external link.
                        Change the setting above to make the dashboard visible
                        to anyone via an external link
                      </Trans>
                    ) : (
                      <Trans>
                        The dashboard is visible to anyone via a link
                      </Trans>
                    )}
                  </Description>
                </VStack>
              </HStack>

              {dashboardProp?.accessKey != null && (
                <>
                  <AccessKeyItem
                    key={dashboardProp.accessKey.key}
                    item={dashboardProp.accessKey}
                    periodDef={periodDef}
                  />
                  {analytics.event(
                    'publicDashboardLink',
                    dashboardProp.accessKey,
                  )}
                </>
              )}
            </Flex>
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
